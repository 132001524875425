import axios from 'axios'
import { API_KEY, MASTER_API_ROOT, EPLUS_API_ROOT } from '../../settings'

const storage = {
  vnvcs: null,
  vaccines: null,
  provinces: null,
  districts: {},
  wards: {}
}

const masterInstance = axios.create({
  baseURL: MASTER_API_ROOT,
  headers: {
    'Content-Type': 'application/json',
    'x-apikey': API_KEY
  }
})

const eplusInstance = axios.create({
  baseURL: EPLUS_API_ROOT
})

export default {
  forms: {
    push (id, data) {
      return eplusInstance
        .post('/apimgd/form', {
          form_id: id,
          ...data
        })
    },
    findRecord (data) {
      return eplusInstance
        .post('/api/vaccine/findRecord',
          data
        )
    },
    findRecordRID (data) {
      return eplusInstance
        .post('/api/vaccine/findRecordRID',
          data
        )
    },
    getTimeslots (date, source) {
      const centerId = source && source == 'SMS' ? 'BV-TAMANH-HANOI' : 'BV-TAMANH-HCM'
      return eplusInstance
        .get(`/api/timeslot?centerId=${centerId}&date=${date}&formId=VaccineBookingOnline`)
    },
    getDateForBooking (data) {
      return eplusInstance
        .post(`/api/timeslot/all?page=1&limit=3000`, data)
    },
    checkBookingExist (data) {
      return eplusInstance
        .post(`/api/dbform/find`, data)
    },
    getRecordKeyWeb (data) {
      return eplusInstance
        .post('/api/vaccine/getRecordKeyWeb',
          data
        )
    },
    getLocation () {
      return eplusInstance
        .get('/api/utils/location')
    },
    getCountrys () {
      return eplusInstance
        .get('/api/utils/location/country')
    },
    getProvinces () {
      return eplusInstance
        .get('/api/utils/location/province')
    },
    getDistricts (id) {
      return eplusInstance
        .get(`/api/utils/location/district/${id}`)
    },
    getWards (id) {
      return eplusInstance
        .get(`/api/utils/location/ward/${id}`)
    }
  },

  master: {
    getVnvcsList ({ size = 100 } = {}) {
      if (storage.vnvcs)
        return Promise.resolve(storage.vnvcs)
      return masterInstance
        .post('/api/app/Center/GetByQuery3rd', {
          size
        })
        .then(res => {
          const data = res.data.data
          storage.vnvcs = data
          return data
        })
    },

    getVaccinesList ({ from = 0, size = 100 } = {}) {
      if (storage.vaccines)
        return Promise.resolve(storage.vaccines)
      return masterInstance
        .post('/api/app/VaccineCategories/GetByQuery3rd', {
          from,
          size,
          sort: { field: 'order', sortOrder: 0 },
          source: {
            excludes: ['description']
          },
          query: {
            bool: {
              must: [
                { term: { isActive: true } },
                { term: { isDelete: false } },
                { term: { allowedToSell: true } },
                { term: { displays: 'web' } }
              ]
            }
          }
        })
        .then(res => {
          const data = res.data.data
          console.log(data.map(i => i.code))
          storage.vaccines = data
          return data
        })
    },

    getProvincesList ({ size = 100 } = {}) {
      if (storage.provinces)
        return Promise.resolve(storage.provinces)
      return masterInstance
        .post('/api/app/MasterData/v2/province/GetByQuery3rd', {
          size
        })
        .then(res => {
          const data = res.data.data
            .sort((a, b) => a.sort - b.sort)
          storage.provinces = data
          return data
        })
    },

    getDistrictsList ({ size = 100, provinceId = undefined } = {}) {
      if (provinceId && storage.districts[provinceId])
        return Promise.resolve(storage.districts[provinceId])
      return masterInstance
        .post('/api/app/MasterData/v2/district/GetByQuery3rd', {
          size,
          query: provinceId !== undefined
            ? {
              bool: {
                must: {
                  term: {
                    provinceId
                  }
                }
              }
            }
            : undefined
        })
        .then(res => {
          const data = res.data.data
            .sort((a, b) => a.sort - b.sort)
          storage.districts[provinceId] = data
          return data
        })
    },

    
    getWardsList ({ size = 100, districtId = undefined } = {}) {
      if (districtId && storage.wards[districtId])
        return Promise.resolve(storage.wards[districtId])
      return masterInstance
        .post('/api/app/MasterData/v2/ward/GetByQuery3rd', {
          size,
          query: districtId !== undefined
            ? {
              bool: {
                must: {
                  term: {
                    districtId
                  }
                }
              }
            }
            : undefined
        })
        .then(res => {
          const data = res.data.data
            .sort((a, b) => a.sort - b.sort)
          storage.wards[districtId] = data
          return data
        })
    }
  }
}

